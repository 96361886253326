import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import 'element-plus/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/index.css';
import Element from 'element-ui';
//单独引入
import {Message} from "element-ui"
import "swiper/css/swiper.min.css"
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
Vue.component('Swiper', Swiper);
Vue.component('SwiperSlide', SwiperSlide);
// Swiper 代表最外侧容器
// SwiperSlide 代表每一张轮播图
Vue.use(Element, { size: 'small', zIndex: 3000 });
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
//挂载
Vue.prototype.$message = Message
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  baseURL: 'www.lowyer.com.cn',
  render: (h) => h(App),
}).$mount("#app");
