import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "",
    alias:'/first',
    component: () => import("../views/homePage.vue"),
  },
  {
    path: "/pages",
    name: "pages",
    component: () => import("../views/pages/nextPage.vue"),
  },
];

const router = new VueRouter({
  routes,
  
});
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router;
